.ExcalidrawModal__overlay {
  display: flex;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex-grow: 0px;
  flex-shrink: 1px;
  z-index: 99998 !important;
  background-color: rgba(40, 40, 40, 0.6);
}
.ExcalidrawModal__actions {
  text-align: end;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.ExcalidrawModal__actions button {
  background-color: #7451A2;
  color: #fff;
  border-radius: 5px;
}
.ExcalidrawModal__actions button:hover {
  background-color: #7451A2;
  color: #fff;
}
.ExcalidrawModal__row {
  position: relative;
  padding: 40px 5px 5px;
  width: 99vw;
  height: 97.5vh;
  border-radius: 8px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.ExcalidrawModal__row > div {
  border-radius: 5px;
}
.ExcalidrawModal__modal {
  position: relative;
  z-index: 99999 !important;
  top: 10px;
  width: auto;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #eee;
}
.ExcalidrawModal__discardModal {
  margin-top: 60px;
  text-align: center;
}