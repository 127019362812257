.custom-link-chip {
  display: inline-block;
  padding: 2px 8px;
  margin-left: 0px;
  margin-right: 4px;
  font-size: 12px;
  border-radius: 12px;
  background-color: #f0f0f0;
  color: #666;
}

.link-type-external .custom-link-chip {
  background-color: #e3f2fd;
  color: #1976d2;
}

.link-type-internal .custom-link-chip {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.custom-link-wrapper {
  display: inline;
  color: rgb(85, 26, 139);
}

a.custom-link:visited {
  color: rgb(85, 26, 139);
}

a.custom-link {
  text-decoration: underline;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: rgb(85, 26, 139);
}

.custom-link-icon {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}